import React from 'react';

import {graphql} from 'gatsby';

import { Row, Col } from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import {Page} from '../contracts/page';

import '../styles/text.scss';

export interface Props {
	data: {
		wpPage: Page;
	};
	location: Location;
}

export const PlainTextPage = (props: Props) => {
	const page = props.data.wpPage;
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area c_text--white">
					<div className="plain-text">
						<Row type="flex" className="c_text page-gutter">
							<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<h1>{page.title}</h1>
								<div dangerouslySetInnerHTML={{__html: page.content}}/>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default PlainTextPage;

export const query = graphql`
	query($id: String!) {
      wpPage(id: { eq: $id }) {
        title
        content
		seo {
		  metaDesc
		  metaKeywords
		  metaRobotsNoindex
		  metaRobotsNofollow
		  opengraphAuthor
		  opengraphDescription
		  opengraphModifiedTime
		  opengraphPublishedTime
		  opengraphPublisher
		  opengraphSiteName
		  opengraphTitle
		  opengraphType
		  opengraphUrl
		  readingTime
		  title
		  twitterTitle
		  schema {
			articleType
			pageType
			raw
		  }
		  twitterDescription
		  twitterImage {
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
      }
    }
`;
